<template>
  <!--待接单-->
  <div style="padding: 20px;">
    <div v-if="showEdit == false && Orderdetails == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键字:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="订单号/案件号/客户/客户电话/客户/服务人员"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="服务商名称:">
                    <el-input
                        clearable
                        v-model="where.acceptor_nickname"
                        placeholder="请输入服务商名称"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="where.order_time"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <!--<DatePicker v-model="where.order_time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" @click="CreatePenaltyOrder()" v-auths="[`${$config.uniquePrefix}orderModule:list:placeanorder`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-xiadan" />
                  <span style="vertical-align: middle">下单</span>
                </el-button>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
              <!--自定义列显示 示例-->
              <template v-slot:order_identity= "{ scope }">
                <div>
                  {{ scope.row.order_identity }}
                </div>
              </template>

              <!--案件地址-->
              <template v-slot:starting_point= "{ scope }">
                <el-tooltip class="item" effect="dark" :content="scope.row.starting_point" placement="top">
                  <div class="shengluehao">
                    {{ scope.row.starting_point }}
                  </div>
                </el-tooltip>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="Todetails(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:info:info`]">详情</el-link>
                <el-link :underline="false" type="success" @click="shouli(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:list:accept`]">受理</el-link>
                <el-link :underline="false" type="danger" @click="openturndown(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:list:cancel`]">取消</el-link>
                <!--<el-link :underline="false" type="primary" style="color: #5976E1!important;">群聊</el-link>-->
                <!--<el-link :underline="false" type="danger" v-auths="[`${$config.uniquePrefix}custom:deactivate`]" @click="CreatePenaltyOrder()">处罚</el-link>-->
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!-- 创建处罚订单或者创建订单组件 -->
    <order-edit v-if="showEdit == true" @show="ShowOrder" :name="orderName" :dict="dict" @done="getList"></order-edit>

    <!-- 订单详情模块 -->
    <order-details v-if="Orderdetails == true" :data="Detailscurrent" :dict="dict" @showDetalis="ShowDetalis"></order-details>

    <!--受理弹窗-->
    <acceptance-edit v-if="showacceptance == true" :dict="dict" :data="current" :visible.sync="showacceptance" @done="show" @refresh="isrefresh"/>

    <!--拒绝弹窗-->
    <turndown-edit :data="turndowncurrent" :visible.sync="showturndown" @ShowTurndown="isShowTurndown"/>

    <!--派单弹窗-->
    <reassignment-edit v-if="showreassignment === true" :data="reassignmentcurrent" :dict="dict" :visible.sync="showreassignment" :repairshop="repairshop" @freshen="isfreshen1"/>

  </div>
</template>

<script>
// 引入创建处罚订单组件
import OrderEdit from '../add/index.vue'
// 引入订单详情组件
import OrderDetails from '../details/index.vue'
// 引入受理
import AcceptanceEdit from './acceptance/acceptance-edit.vue'
//引入拒绝
import TurndownEdit from './components/turndown-edit.vue'
// 引入派单弹窗
// import ReassignmentEdit from './components/reassignment-edit.vue'
import ReassignmentEdit from '../toBeDispatched/components/reassignment-edit.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'

import Bus from '@/utils/bus'

// 引入的接口
import {getdelay_join, order_list_header} from "@/api/orderModule";

import ReportingEdit from "@/views/orderModule/details/components/reporting-edit.vue";
import Vue from "vue";


export default {
  // 组件生效
  components: {
    ReportingEdit,
    PackageSortElement,
    OrderEdit,
    OrderDetails,
    AcceptanceEdit,
    ReassignmentEdit,
    TurndownEdit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "车牌号",
          prop: "",
          isShow: true,
          fixed:'left',
        },
        {
          label: "下单时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "状态",
          prop: "status_label",
          isShow: true
        },
        {
          label: "车主姓名",
          prop: "",
          isShow: true
        },
        {
          label: "车主电话",
          prop: "",
          isShow: true
        },
        {
          label: "救援项目",
          prop: "items_name",
          isShow: true
        },
        // {
        //   label: "下单时间",
        //   prop: "order_time",
        //   isShow: true
        // },
        {
          label: "案件地址",
          prop: "starting_point",
          slot: 'starting_point',
          isShow: true
        },
        {
          label: "服务商",
          prop: "",
          isShow: true
        },
        {
          label: "接单人",
          prop: "driver_name",
          isShow: true
        },
        {
          label: "接单方式",
          prop: "",
          isShow: true
        },
        {
          label: "受理人",
          prop: "acceptor_nickname",
          isShow: true
        },
        {
          label: "救援类型",
          // 事故 非事故
          prop: "",
          isShow: true
        },
        {
          label: "订单来源",
          prop: "channel_name",
          isShow: true
        },
        {
          label: "订单号/案件号",
          prop: "order_id",
          slot:'order_id',
          isShow: true
        },
        {
          label: "业务归属",
          prop: "",
          isShow: true
        },
        // {
        //   label: "操作",
        //   width: "200",
        //   slot: "operationSlot",
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      // 是否显示创建处罚订单弹窗
      showEdit: false,
      // 是创建处罚订单还是创建订单 默认未创建处罚订单
      orderName: 'addpunish',

      // 订单详情模块
      Detailscurrent:null,
      Orderdetails:false,

      //受理
      current:null,
      showacceptance:false,

      //拒绝
      turndowncurrent:null,
      showturndown:false,

      //派单
      reassignmentcurrent:null,
      showreassignment:false,

      //字典
      dict: {
        type:{}
      },

      //是否显示附近修理厂
      repairshop:false

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '订单字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

      // 调用获取自定义表头
      this.getHeader();

    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 接受子组件传递的值
    ShowOrder(value){
      console.log(value);
      // 隐藏子组件
      this.showEdit = value;
        // 调用获取自定义表头
        this.getHeader();
      // 调用一下获取列表的接口
      this.getList();
    },

    // 接收 详情子组件传递的值
    ShowDetalis(value){
      console.log(value)
      // 隐藏详情子组件
      this.Orderdetails = false;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    // 点击处罚按钮按钮
    CreatePenaltyOrder(){
      // 显示创建订单模块
      this.showEdit = true;
    },

    // 获取自定义表头
    getHeader() {
      order_list_header().then(res => {
        // console.log(res)
        // console.log(this.columns)
        // push表头
        res.data.forEach(item => {
          let data = {
            label: item.label,
            prop: item.field,
            isShow: true
          }
          if (this.columns.some(item => JSON.stringify(data) === JSON.stringify(item))) {
            console.log('数组中存在该对象');
          } else {
            console.log('数组中不存在该对象');
            this.columns.push(data)
          }
        })

        // 判断下是否存在了操作列 不存在则追加
        // 使用includes()方法进行判断
        // push操作列
        let data1 = {
          label: "操作",
          slot: "operationSlot",
          fixed:'right',
          width:'150',
          align:'center',
          isShow: true
        }
        if (this.columns.some(item => JSON.stringify(data1) === JSON.stringify(item))) {
          console.log('数组中存在操作列');
        } else {
          console.log('数组中不存在操作列');
          this.columns.push(data1)
        }


      })
    },

    // 获取列表
    getList(){
      getdelay_join(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择时间
    datechange(e){
      this.where.order_time = e;
    },

    // 查询条件
    QueryList(){
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },


    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    Todetails(row){
      this.Detailscurrent = row;
      // 显示详情子组件
      this.Orderdetails = true;
    },

    //点击受理
    shouli(row){
      this.current = row;
      this.showacceptance = true;
    },

    //接收是否派单组件传递的值
    show(value){
      console.log(value);
      this.showacceptance = value;
      this.showreassignment = true;
      this.reassignmentcurrent = this.current;
    },
    isrefresh(value){
      this.showacceptance = value;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },
    isfreshen1(value){
      this.getList();
    },

    //点击拒绝
    openturndown(row){
      this.turndowncurrent = row;
      this.showturndown = true;
    },
    //接收拒绝组件传递的值
    isShowTurndown(value){
      this.showturndown = value;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
